<script>
  import Swiper, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
  import { TRANSITION_LEAVE_DURATION } from '@/router'

  Swiper.use([Navigation, Pagination, Scrollbar, A11y])

  /**
   * A swipeable slider (AKA carousel or slideshow).
   */
  export default {
    props: {
      /** The active slide index. */
      activeIndex: {
        type: Number,
        default: 0,
      },
      /** The number of slides per view. If not set, it will be determined automatically based on the slides’ width. */
      slidesPerView: {
        type: Number,
        default: undefined,
      },
      /** Whether the slider has navigation (previous and next slide) buttons. */
      hasNavigation: {
        type: Boolean,
        default: false,
      },
      /** Whether the slider has pagination (dots indicating the selected slide and the total number of slides). */
      hasPagination: {
        type: Boolean,
        default: false,
      },
      /** Whether the slider has a scrollbar for pagination. */
      hasScrollbar: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        swiper: null,
        swiperRef: null,
        prevButtonRef: null,
        nextButtonRef: null,
        paginationRef: null,
        scrollbarRef: null,
        innerActiveIndex: null,
        slideNodes: [],
      }
    },
    computed: {
      slideCount() {
        return this.slideNodes.length
      },
    },
    watch: {
      activeIndex: {
        immediate: true,
        handler() {
          this.innerActiveIndex = this.activeIndex
          if (this.swiper) {
            this.swiper.slideTo(this.innerActiveIndex)
          }
        },
      },
      slidesPerView() {
        if (this.swiper) {
          this.swiper.params.slidesPerView = this.slidesPerView
        }
      },
    },
    mounted() {
      this.swiper = new Swiper(this.swiperRef, {
        initialSlide: this.activeIndex,
        slidesPerView: this.slidesPerView ?? 'auto',
        watchOverflow: true,
        navigation: {
          prevEl: this.prevButtonRef,
          nextEl: this.nextButtonRef,
        },
        pagination: {
          el: this.paginationRef,
          clickable: true,
        },
        scrollbar: {
          el: this.scrollbarRef,
          draggable: true,
        },
      })
      this.swiper.on('slideChange', () => {
        this.innerActiveIndex = this.swiper.activeIndex
        /** Emitted when the active slide changes. */
        this.$emit('slideChange', this.innerActiveIndex)
      })
      this.refreshSlideNodes()
    },
    updated() {
      this.swiper.update()
      this.$skipNextTick(() => this.refreshSlideNodes())
    },
    async beforeDestroy() {
      await this.$wait(TRANSITION_LEAVE_DURATION)
      this.swiper.destroy()
    },
    methods: {
      refreshSlideNodes() {
        this.slideNodes = this.$scopedSlots.default?.()?.filter((node) => node.tag) ?? []
      },
    },
  }
</script>

<template>
  <div>
    <div v-ref="swiperRef" class="swiper-container" @scroll="$event.target.scrollLeft = 0">
      <ul class="swiper-wrapper">
        <!-- @slot The slider’s content. Should contain one or multiple `BaseSliderSlide` components. -->
        <slot />
      </ul>

      <div v-show="hasPagination && slideCount > 1">
        <div v-ref="paginationRef" class="swiper-pagination" @click.stop />
      </div>

      <div v-show="hasScrollbar && slideCount > 1">
        <div v-ref="scrollbarRef" class="swiper-scrollbar" @click.stop />
      </div>
    </div>
    <div v-show="hasNavigation && slideCount > 1" class="swiper-navigation">
      <div v-ref="prevButtonRef" class="swiper-button-prev" />
      <div v-ref="nextButtonRef" class="swiper-button-next" />
    </div>
  </div>
</template>

<style lang="postcss">
  /* purgecss start ignore */
  @import 'swiper/swiper-bundle.css';

  .swiper {
    &-container {
      --swiper-navigation-color: black;
      --swiper-pagination-color: black;
      @apply overflow-hidden;
    }

    &-pagination {
      @apply bottom-0 mb-2 flex w-auto p-1;

      left: 50%;
      transform: translateX(-50%);

      &-bullet {
        @apply mx-2px my-0 bg-transparent border border-black outline-none opacity-100;

        &:focus {
          @apply shadow-outline-primary-50;
        }

        &-active {
          @apply bg-black;
        }
      }
    }

    &-scrollbar {
      @apply h-1 rounded-none bg-gray-opacity68;

      &-drag {
        @apply rounded-none bg-black;
      }
    }

    &-navigation {
      @apply static;
    }

    &-button-prev,
    &-button-next {
      @apply rounded-sm;

      &::after {
        @apply text-xl;
      }

      &.swiper-button-disabled {
        @apply hidden;
      }
    }

    &-button-prev {
      @apply absolute left-0 -mt-16 -ml-9 text-black h-10 w-10;

      @screen md {
        @apply h-14 w-14 -mt-22;
      }
    }

    &-button-next {
      @apply absolute right-0 -mt-16 -mr-9 text-black h-10 w-10;

      @screen md {
        @apply h-14 w-14 -mt-22;
      }
    }
  }
  /* purgecss end ignore */
</style>
